import React from 'react';
import cx from 'classnames';
import dynamic from 'next/dynamic';

import styles from './index.module.scss';

const Button = dynamic(() => import('#app/UI/molecules/new-button'));
const CardNews = dynamic(() => import('#app/UI/organisms/card-news'));
const Typography = dynamic(() => import('#app/UI/atoms/typography-updated'));

const dataNews = [
  {
    title: 'María Vélez de Crack the Code: ',
    description: '“Educar a los niños en tecnología es fundamental”',
    srcImg: '/home/news/news-1.webp',
    url: 'https://caracol.com.co/2024/03/06/maria-velez-de-crack-the-code-educar-a-los-ninos-en-tecnologia-es-fundamental/'
  },
  {
    title: 'Crack the Code: ',
    description: '“La inteligencia artificial puede mejorar el aprendizaje estudiantil”',
    srcImg: '/home/news/news-2.webp',
    url: 'https://caracol.com.co/2024/03/11/la-inteligencia-artificial-puede-mejorar-el-aprendizaje-estudiantill-crack-the-code/'
  },
  {
    title: 'Barranquilla: ',
    description:
      '“Le apuestan a la formación de más de 3 mil estudiantes en programación web”',
    srcImg: '/home/news/news-3.webp',
    url: 'https://caracol.com.co/2024/04/05/en-barranquilla-le-apuestan-a-la-formacion-de-mas-de-3-mil-estudiantes-en-programacion-web/'
  }
];

const News = () => {
  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <Typography className={cx(styles.title)} tag="h2">
          Novedades
        </Typography>
      </div>

      <div className={styles.containerCards}>
        {dataNews.map((item, idx) => (
          <CardNews {...item} key={`card-new-${idx}`} />
        ))}
      </div>

      <Button
        className={styles.button}
        type="url"
        target="_blank"
        href="https://blog.crackthecode.la/"
        variant="orange"
        width="100%"
        height="56px"
        textOptions={{
          fontSize: [20, 20],
          color: '#171A1A'
        }}
      >
        Explora nuestro blog
      </Button>
    </section>
  );
};

export default News;
